import React, {useState, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import AppLayout from "@awsui/components-react/app-layout";
import FormField from "@awsui/components-react/form-field";
import Alert from "@awsui/components-react/alert";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import SideNavigation from '@awsui/components-react/side-navigation';
import Button from "@awsui/components-react/button";
import TokenGroup from "@awsui/components-react/token-group";
import TopNavigation from "@awsui/components-react/top-navigation"
import SpaceBetween from "@awsui/components-react/space-between";
import ProgressBar from "@awsui/components-react/progress-bar";
import Amplify, {Auth, Storage} from 'aws-amplify';
import {Authenticator} from '@aws-amplify/ui-react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const date = new Date();
const currentTime = formattedDateTime(date);

function formattedDateTime(date) {
  const y = date.getFullYear();
  const m = ('0' + (date.getMonth() + 1)).slice(-2);
  const d = ('0' + date.getDate()).slice(-2);
  const h = ('0' + date.getHours()).slice(-2);
  const mi = ('0' + date.getMinutes()).slice(-2);
  const s = ('0' + date.getSeconds()).slice(-2);

  return y + m + d + h + mi + s;
}


const appLayoutLabels = {
    navigation: 'Side navigation',
    navigationToggle: 'Open side navigation',
    navigationClose: 'Close side navigation',
    notifications: 'Notifications',
    tools: 'Help panel',
    toolsToggle: 'Open help panel',
    toolsClose: 'Close help panel'
};

const ServiceNavigation = () => {
    const location = useLocation();
    let navigate = useNavigate();

    function onFollowHandler(event) {
        if (!event.detail.external) {
            event.preventDefault();
            navigate(event.detail.href);
        }
    }

    return (
        <SideNavigation
            activeHref={location.pathname}
            header={{href: "/", text: "LESTO & DERTNE"}}
            onFollow={onFollowHandler}
            items={[
                {type: "link", text: "Upload", href: "/"},
                // {type: "divider"},
                // {
                //     type: "link",
                //     text: "AWS Solutions Architect",
                //     href: "https://workshops.aws",
                //     external: true
                // }
            ]}
        />
    );
}

function formatBytes(a, b = 2, k = 1024) {
    let d = Math.floor(Math.log(a) / Math.log(k));
    return 0 === a ? "0 Bytes" : parseFloat((a / Math.pow(k, d)).toFixed(Math.max(0, b))) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d];
}

const Content = () => {
    const hiddenFileInput = useRef(null);
    const [visibleAlert, setVisibleAlert] = useState(false);
    const [countDown, setCountDown] = useState(false);
    const [uploadList, setUploadList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [historyList, setHistoryList] = useState([]);
    const [historyCount, setHistoryCount] = useState(0);
    const handleClick = () => {
        hiddenFileInput.current.value = ""; // This avoids errors when selecting the same files multiple times
        hiddenFileInput.current.click();
    };
    const handleChange = e => {
        e.preventDefault();
        let i, tempUploadList = [];
        for (i = 0; i < e.target.files.length; i++) {
            tempUploadList.push({
                label: e.target.files[i].name,
                labelTag: formatBytes(e.target.files[i].size),
                description: 'File type: ' + e.target.files[i].type,
                icon: 'file',
                id: i
            })
        }
        setUploadList(tempUploadList);
        setFileList(e.target.files);
    };

    function progressBarFactory(fileObject) {
        let localHistory = historyList;
        const id = localHistory.length;
        localHistory.push({
            id: id,
            percentage: 0,
            filename: fileObject.name,
            filetype: fileObject.type,
            filesize: formatBytes(fileObject.size),
            status: 'in-progress'
        });
        setHistoryList(localHistory);
        return (progress) => {
            let tempHistory = historyList.slice();
            const percentage = Math.round((progress.loaded / progress.total) * 100);
            tempHistory[id].percentage = percentage;
            if (percentage === 100) {
                tempHistory[id]['status'] = 'success';
            }
            setHistoryList(tempHistory);
        };
    }

    const handleUpload = () => {
        if (uploadList.length === 0) {
            setVisibleAlert(true);
        } else {
            console.log('Uploading files to S3');
            let i, progressBar = [], uploadCompleted = [];
            for (i = 0; i < uploadList.length; i++) {
                // If the user has removed some items from the Upload list, we need to correctly reference the file
                const id = uploadList[i].id;
                progressBar.push(progressBarFactory(fileList[id]));
                setHistoryCount(historyCount + 1);
                // uploadCompleted.push(Storage.put(fileList[id].name, fileList[id], {
                // uploadCompleted.push(Storage.put(`currentTime${path.extname(fileList[id].name)}`, fileList[id], {
                uploadCompleted.push(Storage.put(`${currentTime}.mov`, fileList[id], {
                        progressCallback: progressBar[i]
                    }).then(result => {
                        // Trying to remove items from the upload list as they complete. Maybe not work correctly
                        // setUploadList(uploadList.filter(item => item.label !== result.key));
                        console.log(`Completed the upload of ${result.key}`);
                    })
                );
            }
            // When you finish the loop, all items should be removed from the upload list
            Promise.all(uploadCompleted)
                .then(() => setUploadList([]))
                .then(() => setCountDown(true));
        }
    }

    const handleDismiss = (itemIndex) => {
        setUploadList([
            ...uploadList.slice(0, itemIndex),
            ...uploadList.slice(itemIndex + 1)
        ]);
    };
    
    const renderTime = ({ remainingTime }) => {
      if (remainingTime === 0) {
        return <div className="timer">
        <a href={`https://3dviewer.net/#model=https://dev-3d-lesto-dertne-com-ingestionbucket917a3a3a-10jsnud8ue4e3.s3.us-west-2.amazonaws.com/${currentTime}/texturedMesh.obj`} >Preview Link </a></div>;
      }
    
      return (
        <div className="timer">
          <div className="text">残り</div>
          <div className="value">{remainingTime}</div>
          <div className="text">seconds</div>
        </div>
      );
    };

    const List = ({list}) => (
        <>
            {list.map((item) => (
                <ProgressBar
                    key={item.id}
                    status={item.status}
                    value={item.percentage}
                    variant="standalone"
                    additionalInfo={item.filesize}
                    description={item.filetype}
                    label={item.filename}
                />
            ))}
        </>
    );
    return (
        <SpaceBetween size="l">
            <Container
                id="s3-upload-multiple-objects"
                header={
                    <Header variant="h2">
                        Upload Video
                    </Header>
                }
            >
                {
                    <div>
                        <Alert
                            onDismiss={() => setVisibleAlert(false)}
                            visible={visibleAlert}
                            dismissAriaLabel="Close alert"
                            dismissible
                            type="error"
                            header="No files selected"
                        >
                            You must select the files that you want to upload.
                        </Alert>

                        <FormField
                            label='Object Upload'
                            description='Click on the Open button and select the files that you want to upload'
                        />

                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={handleClick}
                                    iconAlign="left"
                                    iconName="upload"
                            >
                                Choose file[s]
                            </Button>
                            <input
                                type="file"
                                ref={hiddenFileInput}
                                onChange={handleChange}
                                style={{display: 'none'}}
                                multiple
                            />
                            <Button variant="primary" onClick={handleUpload}>Upload</Button>
                        </SpaceBetween>

                        <TokenGroup
                            onDismiss={({detail: {itemIndex}}) => {
                                handleDismiss(itemIndex)
                            }}
                            items={uploadList}
                            alignment="vertical"
                            limit={10}
                        />
                    </div>
                }
            </Container>
            <Container
                id="history"
                header={
                    <Header variant="h2">
                        History
                    </Header>
                }
            >
                <List list={historyList}/>
            </Container>            
            <Container
                id="preview"
                header={
                    <Header variant="h2">
                        Preview 
                    </Header>
                }
            >
              <div className="timer-wrapper">
              <CountdownCircleTimer
                isPlaying={countDown}
                duration={300}
                colors="#476F7C"
                onComplete={() => {
              // do your stuff here
              return { shouldRepeat: false, delay: 1.5 } // repeat animation in 1.5 seconds
                }}
              >
                {renderTime}
              </CountdownCircleTimer>
              </div>
            </Container>

        </SpaceBetween>

    );
};

function App() {
    const [navigationOpen, setNavigationOpen] = useState(true);
    const navbarItemClick = e => {
        console.log(e);
        if (e.detail.id === 'signout') {
            Auth.signOut().then(() => {
                window.location.reload();
            });
        }
    }

    return (
        <Authenticator>
            {({signOut, user}) => (
                <>
                    <div id="navbar" style={{fontSize: 'body-l !important', position: 'sticky', top: 0, zIndex: 1002}}>
                        <TopNavigation
                            identity={{
                                href: "#",
                                title: "",
                                logo: {
                                    src: "https://res.cloudinary.com/lesto-dertne/f_auto,c_limit,w_3840,q_auto/resources/index/LESTO_WH.svg",
                                    alt: "LESTO&DERTNE"
                                }
                            }}
                            utilities={[
                                // {
                                //     type: "button",
                                //     text: "AWS",
                                //     href: "https://aws.amazon.com/",
                                //     external: true,
                                //     externalIconAriaLabel: " (opens in a new tab)"
                                // },
                                // {
                                //     type: "menu-dropdown",
                                //     text: user.username,
                                //     description: user.username,
                                //     iconName: "user-profile",
                                //     onItemClick: navbarItemClick,
                                //     items: [
                                //         {id: "profile", text: "Profile"},
                                //         {id: "preferences", text: "Preferences"},
                                //         {id: "security", text: "Security"},
                                //         {
                                //             id: "feedback",
                                //             text: "Feedback",
                                //             href: "#",
                                //             external: true,
                                //             externalIconAriaLabel:
                                //                 " (opens in new tab)"
                                //         },
                                //         {id: "signout", text: "Sign out"}
                                //     ]
                                // }
                            ]}
                            i18nStrings={{
                                searchIconAriaLabel: "Search",
                                searchDismissIconAriaLabel: "Close search",
                                overflowMenuTriggerText: "More"
                            }}
                        />
                    </div>
                    <AppLayout
                        content={<Content/>}
                        headerSelector='#navbar'
                        navigation={<ServiceNavigation/>}
                        navigationOpen={navigationOpen}
                        onNavigationChange={({detail}) => setNavigationOpen(detail.open)}
                        ariaLabels={appLayoutLabels}
                    />
                </>
            )}
        </Authenticator>
    );
}

export default App;
